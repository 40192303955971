import sha1 from "sha1";
import { api } from "@/helpers/api";

const state = {
  added: [],
  payments: [],
  all: [],
  categories: [],
  prod_in_cat: [],
  modifier_sets: [],
  discounts: [],
  history: [],
  historyPos: 0,
  loading: false,
  siteSettings: {},
  localSettings: [],
  cartOrder: null,
  orderName: false,
  orderNotes: false,
  orderDelivery: false,
  socketConnected: false,
  webStore: false,
  futurePickup: false,
  stripePaymentIntent: false,
  stripeTotal: 0,
  currentPage: "store",
  osdefault: true,
  darkmode: false,
  lightmode: false,
  site: false,
  station: false,
  company: false,
  curAccount: {},
  curVoucher: {},
  user: {},
  cartProceed: true,
  webOrder: {},
  weekOpenClose: {},
  currency: null,
};

const getters = {
  currency: (state) => state.currency,
  getWeekOpenClose: (state) => state.getWeekOpenClose,
  getWebOrder: (state) => state.webOrder,
  cartProceed: (state) => state.cartProceed,
  user: (state) => state.user,
  curAccount: (state) => state.curAccount,
  curVoucher: (state) => state.curVoucher,
  accounts: (state) => state.accounts,
  vouchers: (state) => state.vouchers,
  site: (state) => state.site,
  station: (state) => state.station,
  company: (state) => state.company,
  cartCount: (state) => state.added.map(({ quantity }) => quantity).reduce((a, b) => a + b, 0),
  currentPage: (state) => state.currentPage,
  webStore: (state) => state.webStore,
  futurePickup: (state) => state.futurePickup,
  socketConnected: (state) => state.socketConnected,
  orderName: (state) => state.orderName,
  orderNotes: (state) => state.orderNotes,
  orderDelivery: (state) => state.orderDelivery,
  siteSettings: (state) => state.siteSettings,
  localSettings: (state) => state.localSettings,
  eftposSettings: (state) => {
    return state.localSettings
      .map((station) => {
        return station.name == window.localStorage.getItem("station") ? station.eftpos : false;
      })
      .filter(Boolean);
  },
  loading: (state) => state.loading,
  allProducts: (state) => state.all, // would need action/mutation if data fetched async
  discounts: (state) => state.discounts,
  allCategories: (state) => state.categories,
  cartOrder: (state) => state.cartOrder,
  // undefined is not an object (evaluating 'e.categories.filter')??
  categories: (state) => {
    if (state.categories) {
      return state.categories.filter((category) => {
        //cannot read properties of undefined reading filter
        return (category.key && "active" in category.key && category.key.active === true) || (category.key && !("active" in category.key));
      });
    }
  },
  prod_in_cat: (state) => state.prod_in_cat,
  modifier_sets: (state) => state.modifier_sets,
  getNumberOfProducts: (state) => (state.all ? state.all.length : 0),
  cartPayments: (state) => {
    return state.payments.map((payment) => {
      return payment;
    });
  },
  history: (state) => state.history,
  cartProducts: (state) => {
    /* this code is really questionable */
    /* the es6 proxies work oddly - I should be able to pass them through and access them via getters in the view */
    /* but it doesn't seem to work */
    var findProductCategory = (product) => {
      //var lowercaseProduct = product.toLowerCase().replace(/ /g, '-');
      var foundCategory = state.categories.find((category) => {
        return (
          category.key.products &&
          category.key.products.find((p) => {
            ////console.log(lowercaseProduct);

            return product === p;
          })
        );
      });
      ////console.log(foundCategory.key.name);
      if (foundCategory) {
        return foundCategory.key.name;
      } else {
        return "Unknown";
      }
    };

    let lineItems = state.added.map(({ id, complete, hash, modifiers, quantity, price }) => {
      const product = state.all.find((p) => p.id === id);
      let modifier_obj = {};
      let modifier_total = 0;

      var clonedModifiers = Object.assign({}, modifiers);
      delete clonedModifiers.value;

      Object.keys(clonedModifiers).map((key) => {
        modifier_obj[key] = clonedModifiers[key].map((i) => i);
        modifier_total += clonedModifiers[key].reduce((total, m) => {
          return total + m.price;
        }, 0);
      });
      ////console.log(product.value._id.slice(8));
      ////console.log(state.categories);
      if (product) {
        //console.log(product)
        return {
          id: product.value._id,
          hash: hash,
          image: Array.isArray(product.value.images) ? product.value.images[0] : false,
          name: product.value.name,
          complete: complete,
          autocomplete: product.value.autocomplete,
          modifiers: modifier_obj,
          modifier_list: Object.values(modifier_obj).flat(),
          price: product.value.price + modifier_total,
          category: findProductCategory(product.value._id.slice(8)),
          quantity,
        };
      } else if (id == "product:fee") {
        return { id: id, hash: hash, price: price, quantity: quantity, name: "Fee" };
      } else if (id.includes("product:custom")) {
        return {
          id: id,
          hash: hash,
          price: price,
          quantity: quantity,
          modifiers: {},
          name: "Custom Value",
          category: "unknown",
          complete: false,
          modifier_list: [],
          autocomplete: true,
        };
      } else {
        console.log("product not found");
      }
    });

    return lineItems;
  },
  modifierset_in_product: (state) => {
    return state.modifier_sets.map((modifier) => {
      return {
        mod: modifier.id,
        products: state.all
          .map((prod) => {
            if (prod.value.modifiersets.includes(modifier.id)) {
              return prod.value;
            }
          })
          .filter((p) => p),
      };
    });
  },
  modifiers: (state) => (set) => {
    return state.modifier_sets
      .map((modifier) => {
        return set.includes(modifier.key) ? modifier.value : null;
      })
      .filter(Boolean);
  },
  active_products: (state) => {
    return state.prod_in_cat.filter((docs) => {
      if (docs.value.active == true) {
        return docs;
      }
    });
  },
  active_products_in_categories: (state) => (cat) => {
    let p = [];
    // console.log("cat", cat);
    if (cat && cat.key) {
      //cannot read properties of undefined reading key
      cat.key.products.forEach(function (prod) {
        let product = state.all.find((x) => x.id == "product:" + prod);
        if (product && product.value.active == true) {
          p.push(product);
        }
      });
    }
    // console.log("p ", p);
    return p;
  },
  productInfo: (state) => (product) => {
    return state.all.find((p) => {
      return "product:" + product === p.id;
    });
  },
  productCat: (state) => (product) => {
    return state.prod_in_cat.find((p) => {
      return product === p.value._id;
    });
  },

  stripeTotal: (state) => state.stripeTotal,

  getClosed: (state) => {
    return state.siteSettings.openClose;
  },
};

// actions
const actions = {
  setSiteClosed({ commit }, status) {
    commit("setClosedMutation", status);
  },
  updateWebOrder({ commit }, data) {
    commit("webOrderMutation", data);
  },

  loading({ commit }) {
    commit("loading");
  },

  async setUser({ commit }) {
    commit("setUserMutation");
  },

  async updateUser({ commit }, user) {
    commit("updateUserMutation", user);
  },

  async setSocketConnected({ commit }, socketConnected) {
    commit("setSocketConnectedMutation", socketConnected);
  },

  async getAllData({ dispatch }) {
    Promise.all([
      dispatch("getAccounts"),
      dispatch("getVouchers"),
      dispatch("loading"),
      dispatch("getProducts"),
      dispatch("getCategories"),
      dispatch("getProductsInCategories"),
      dispatch("getDiscounts"),
      dispatch("getModifierSets"),
      dispatch("getSettings"),
      dispatch("getHistory"),
      dispatch("setUser"),
      dispatch("getCurrency"),
    ]);
    dispatch("loading");
  },

  async getCurrency({ commit }) {
    const companyString = await localStorage.getItem("company");
    const companySettings = await api.get('/api/company/get_settings/"' + companyString + '"');
    let currency = companySettings.rows[0].value.currency;
    commit("setCurrencyMutation", currency);
  },

  async getSettings({ commit }) {
    if (!Object.keys(state.siteSettings).length) {
      const companyString = await localStorage.getItem("company");
      const siteString = await window.localStorage.getItem("site");
      const companyResults = await api.get('/api/company/get_pos/"' + companyString + '"');
      const settings = companyResults.rows[0].value.settings;
      commit("initialiseSiteSettingsMutation", settings[siteString]);
    }

    const companyString = await localStorage.getItem("company");
    const site = await localStorage.getItem("site");
    const localStation = await localStorage.getItem("station");
    const pos_stations = await api.get('/api/company/get_pos/"' + companyString + '"');
    const stations = pos_stations.rows[0].value.stations;
    //console.log(stations[0].name, site, localStation);
    const newLocal = stations.filter((station) => station.site == site && station.name == localStation);
    // const stationName = await window.localStorage.getItem("station")
    // stations.forEach(el => //console.log(el));
    commit("updateLocalSettingsMutation", newLocal);
    commit("setCompanyMutation", companyString);
  },

  async getAccounts({ commit }) {
    if (state.siteSettings.accounts) {
      const accounts = await api.getUrl("/api/get_all_accounts", "?reduce=true&group=true", true);
      commit("initialiseAccountsMutation", accounts.rows);
    }
  },

  async getVouchers({ commit }) {
    if (state.siteSettings.vouchers) {
      const accounts = await api.getUrl("/api/get_all_vouchers", "?reduce=true&group=true", true);
      commit("initialiseVouchersMutation", accounts.rows);
    }
  },

  async accountHistory({ commit }, account) {
    const accounts = await api.getUrl("/api/get_all_accounts", '?reduce=false&include_docs=true&key=["' + account[0] + '","' + account[1] + '"]', true);
    commit("initialiseAccountMutation", accounts.rows);
  },

  async voucherHistory({ commit }, account) {
    const accounts = await api.getUrl("/api/get_all_vouchers", '?reduce=false&include_docs=true&key=["' + account[0] + '","' + account[1] + '"]', true);
    commit("initialiseVoucherMutation", accounts.rows);
  },

  async getHistory({ commit }) {
    const history = await api.getUrl("/api/get_all_orders", "?limit=50&descending=true", true);
    commit("updateHistoryPosMutation", history.rows.length);
    commit("initialiseHistoryMutation", history.rows);
  },

  async resetHistory({ commit }) {
    const history = await api.getUrl("/api/get_all_orders", "?limit=50&descending=true", true);
    commit("clearHistoryPosMutation");
    commit("initialiseHistoryMutation", history.rows);
  },

  async appendHistory({ commit }) {
    const history = await api.getUrl("/api/get_all_orders", "?limit=50&descending=true&skip=" + state.historyPos, true);
    commit("updateHistoryPosMutation", history.rows.length);
    //state.historyPos += 10;
    history.rows.forEach((row) => {
      ////console.log(row);
      commit("appendHistoryMutation", JSON.parse(JSON.stringify(row)));
    });
  },

  async prependHistoryNew({ commit }, newOrder) {
    commit("updateHistoryPosMutation", 1);
    commit("prependHistoryMutation", JSON.parse(JSON.stringify(newOrder)));
  },

  async findAndUpdateHistoryDoc({ commit }, order) {
    commit("findAndUpdateHistoryDocMutation", order);
  },

  async addAccountDoc({ commit }) {
    actions.getAccounts({ commit });
    //commit("addAccountDocMutation", JSON.parse(JSON.stringify(doc)));
  },

  async addVoucherDoc({ commit }) {
    actions.getVouchers({ commit });
    //commit("addAccountDocMutation", JSON.parse(JSON.stringify(doc)));
  },

  async getProducts({ commit }) {
    const products = await api.getUrl("/api/products/get", "?include_docs=false", true);
    commit("initialiseProductsMutation", products.rows);
    /***  if (!state.all.length) {
          const products = await api.getUrl('/api/products/get', '?include_docs=false', true);
          commit('initialiseProductsMutation', products.rows)
        }***/
  },

  async getCategories({ commit }) {
    const categories = await api.getUrl("/api/categories/get", "?include_docs=false", true);
    commit("initialiseCategoriesMutation", categories.rows);
  },

  async getProductsInCategories({ commit }) {
    const categories = await api.getUrl("/api/products_in_categories/get", "?include_docs=false", true);
    commit("initialiseProdInCatMutation", categories.rows);
  },

  async getDiscounts({ commit }) {
    const discounts = await api.getUrl("/api/discounts/get", "?include_docs=false", true);
    commit("initialiseDiscountsMutation", discounts.rows);
  },

  async getModifierSets({ commit }) {
    const sets = await api.getUrl("/api/modifiersets/get", "?include_docs=false", true);
    commit("initialiseModifierSetsMutation", sets.rows);
  },

  addToCart({ commit }, product) {
    // call the mutation with matching object
    commit("addToCartMutation", {
      id: product.id,
      modifiers: product.modifiers,
      price: product.price,
      hash: product.hash,
    });
  },

  changeLineItemQuantity({ commit }, hash) {
    commit("changeLineItemQuantityMutation", {
      productHash: hash.productHash,
      delta: hash.delta,
    });
  },

  removeFromCart({ commit }, product) {
    commit("removeFromCartMutation", {
      id: product,
    });
  },

  clearCart({ commit }) {
    commit("clearCartMutation");
  },

  clearPayments({ commit }) {
    commit("clearPaymentsMutation");
  },

  deletePayment({ commit }, paymentIndex) {
    commit("deletePaymentMutation", paymentIndex);
  },

  deleteEftposPayment({ commit }) {
    const payments = state.payments.map((payment) => payment.type);
    commit("deletePaymentMutation", payments.lastIndexOf("eftpos"));
  },

  updateModifierSet({ commit }, ms) {
    commit("updateModifierSetMutation", ms);
  },

  deleteModifierSet({ commit }, data) {
    commit("deleteModifierSetMutation", data);
  },

  deleteDiscount({ commit }, data) {
    commit("deleteDiscountMutation", data);
  },

  updateItem({ commit }, data) {
    commit("updateItemMutation", data);
  },

  startWebItem({ commit }, data) {
    commit("startWebItemMutation", data);
  },

  updateDiscount({ commit }, discount) {
    commit("updateDiscountMutation", discount);
  },

  updateSettings({ commit }, settings) {
    commit("updateSiteSettingsMutation", settings[0]);
    commit("updateLocalSettingsMutation", settings[1]);
  },

  updateCompanySettings({ commit }, doc) {
    commit("updateSiteSettingsMutation", doc);
  },

  addProduct({ commit }, data) {
    commit("addProductMutation", data);
  },

  updateProduct({ commit }, data) {
    commit("updateProductMutation", data);
  },

  updateCategory({ commit }, data) {
    commit("updateCategoryMutation", data);
  },

  addCategory({ commit }, category) {
    commit("addCategoryMutation", category);
  },

  deleteCategory({ commit }, data) {
    commit("deleteCategoryMutation", data);
  },

  initialiseCartFromLocalStorage({ commit }, cart) {
    //console.log("initialiseCartFromLocalStorage ", cart)
    commit("initialiseCartFromLocalStorageMutation", cart);
  },

  initialiseCartFromHistory({ commit }, cart) {
    //console.log("initialiseCartFromHistory ", cart)
    commit("initialiseCartFromHistoryMutation", cart);
  },

  addPayment({ commit }, obj) {
    commit("addPaymentMutation", obj);
  },

  setStripePaymentIntent({ commit }, intent) {
    commit("setStripePaymentIntentMutation", intent);
  },

  setWebStore({ commit }, status) {
    commit("setWebStoreMutation", status);
  },

  setFuturePickup({ commit }, status) {
    commit("setFuturePickupMutation", status);
  },

  setStripeTotal({ commit }, total) {
    commit("setStripeTotalMutation", total);
  },

  setCurrentPage({ commit }, page) {
    commit("currPage", page);
  },

  setStation({ commit }, station) {
    commit("setStationMutation", station);
  },

  setSite({ commit }, site) {
    commit("setSiteMutation", site);
  },

  setCompany({ commit }, company) {
    commit("setCompanyMutation", company);
  },

  checkCartModifier({ commit }, mod) {
    commit("checkCartModifierMutation", mod);
  },
};

// mutations
const mutations = {
  setClosedMutation(state, status) {
    state.siteSettings.openClose = status;
  },

  async webOrderMutation(state, data) {
    state.webOrder = data;
    await window.localStorage.setItem("webOrder", JSON.stringify(data));
  },

  async setUserMutation(state) {
    const id = JSON.parse(window.localStorage.getItem("userID"));
    if (id) {
      let res = await api.get("/api/get_user/" + window.localStorage.getItem("company") + "/" + id);
      state.user = res;
      // res == 'Internal Server Error' ? (window.localStorage.removeItem("userID"), state.user = {}) :
    } else {
      state.user = {};
    }
  },

  checkCartModifierMutation(state, mod) {
    if (mod.quantity > 0) {
      state.cartProceed = true;
    } else {
      state.cartProceed = false;
    }
    //console.log(mod);
  },

  async updateUserMutation(state, user) {
    state.user = user;
  },

  initialiseAccountsMutation(state, accounts) {
    //console.log(accounts);
    state.accounts = accounts;
  },

  initialiseAccountMutation(state, account) {
    //console.log(accounts);
    state.curAccount = account;
  },

  initialiseVouchersMutation(state, vouchers) {
    state.vouchers = vouchers;
  },

  initialiseVoucherMutation(state, voucher) {
    //console.log(voucher);
    state.curVoucher = voucher;
  },

  currPage(state, page) {
    state.currentPage = page;
  },

  loading(state) {
    state.loading = !state.loading;
  },

  addToCartMutation(state, product) {
    var clonedModifiers = Object.assign({}, product.modifiers);
    //delete clonedModifiers.value;

    let modHash = Object.keys(clonedModifiers).map((mods) => [mods, clonedModifiers[mods].map((m) => m.name)]);
    //let modHash = 
    let newHash = sha1(product.id + modHash);

    /* a lineItem is a single product with it's combination of modifiers on the current cart */
    const lineItem = state.added.find((lineItem) => lineItem.hash === newHash);
    console.log('line', lineItem, newHash, clonedModifiers);
    // if the lineItem is undefined add it to the state
    if (lineItem == undefined) {
      state.added.push({
        hash: newHash,
        id: product.id,
        complete: false,
        modifiers: JSON.parse(JSON.stringify(Object.assign({}, product.modifiers))),
        price: product.price,
        quantity: 1,
      });
      //console.log("state ", state);
    } else {
      lineItem.quantity++;
    }

  },

  changeLineItemQuantityMutation(state, { productHash, delta }) {
    const lineItem = state.added.find((lineItem) => lineItem.hash === productHash);
    if (lineItem && lineItem.quantity) {
      lineItem.quantity = lineItem.quantity + delta; //cannot read properties of undefined reading quantity
    }
    if (lineItem && delta < 0) {
      const index = state.added.findIndex((lineItem) => lineItem.hash === productHash);

      if (lineItem.quantity < 1) {
        state.added.splice(index, 1);
      }
    }
  },

  setSocketConnectedMutation(state, value) {
    state.socketConnected = value;
  },

  initialiseProductsMutation(state, value) {
    // console.log(JSON.stringify(value));
    state.all = value;
  },

  initialiseHistoryMutation(state, value) {
    state.history = value;
  },

  updateHistoryPosMutation(state, value) {
    state.historyPos += value;
  },

  clearHistoryPosMutation(state) {
    state.historyPos = 0;
  },

  appendHistoryMutation(state, value) {
    state.history.push(value);
  },

  prependHistoryMutation(state, value) {
    state.history.splice(0, 0, value);
  },

  findAndUpdateHistoryDocMutation(state, value) {
    // eslint-disable-next-line no-unused-vars
    let foundOrder = state.history.find((order) => {
      return order.value._id === value.doc._id;
    });
    //console.log("findAndUpdateHistoryDocMutation ", foundOrder)
    // console.log("findMutation", value);
    foundOrder.value = value.value;
  },

  initialiseDiscountsMutation(state, value) {
    state.discounts = value;
  },

  initialiseSiteSettingsMutation(state, settings) {
    state.siteSettings = settings;
  },

  initialiseLocalSettingsMutation(state, settings) {
    state.localSettings = settings;
  },

  updateSiteSettingsMutation(state, siteSettings) {
    state.siteSettings = siteSettings;
  },

  async updateLocalSettingsMutation(state, localSettings) {
    state.localSettings = localSettings;
    window.localStorage.setItem("localSettings", JSON.stringify(state.localSettings));
  },

  addProductMutation(state, product) {
    state.all.push({
      id: product._id,
      key: product._id,
      doc: product,
      value: product,
      images: [],
    });
  },

  updateProductMutation(state, product) {
    let prod = state.all.find((p) => p.id === product._id);
    if (prod) {
      prod.doc = product;
      prod.value = product;
    } else {
      state.all.push({
        id: product._id,
        key: product._id,
        doc: product,
        value: product,
        images: [],
      });
    }
  },

  async updateItemMutation(state, product) {
    if (Object.keys(state.webOrder).length != 0) {
      state.webOrder.order.products.forEach((prod) => {
        if (prod.hash == product.hash) {
          prod.complete = true;
        }
      });
    }
    await window.localStorage.setItem("webOrder", JSON.stringify(state.webOrder));
  },

  async startWebItemMutation(state, doc) {
    //console.log(doc);
    if (Object.keys(state.webOrder).length != 0) {
      state.webOrder.order.products.forEach((prod) => {
        if (prod.hash == doc.pk) {
          if (Array.isArray(prod.events)) {
            prod.events.push(doc.event);
          } else {
            prod.events = [doc.event];
          }
        }
      });
    }
    await window.localStorage.setItem("webOrder", JSON.stringify(state.webOrder));
  },

  updateDiscountMutation(state, discount) {
    let disc = state.discounts.find((d) => d.id === discount._id);
    if (disc) {
      disc.doc = discount;
      disc.value = discount;
    } else {
      state.discounts.push({ doc: discount, value: discount });
    }
    //console.log(state.discounts.length);
  },

  initialiseCategoriesMutation(state, value) {
    state.categories = value;
  },

  addCategoryMutation(state, value) {
    state.categories.push(value);
  },

  updateCategoryMutation(state, category) {
    let cat = state.categories.find((c) => c.id === category._id);
    if (cat) {
      cat.doc = category;
      cat.key = category;
      cat.value = category;
    } else {
      state.categories.push({ id: category._id, key: category, doc: category, value: category });
      state.categories = state.categories.sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
    }
  },

  initialiseProdInCatMutation(state, value) {
    state.prod_in_cat = value;
  },

  initialiseModifierSetsMutation(state, value) {
    state.modifier_sets = value;
  },

  updateModifierSetMutation(state, doc) {
    let foundSet = state.modifier_sets.find((modifier_set) => modifier_set.id === doc._id);
    if (foundSet) {
      foundSet.doc = doc;
      foundSet.value = doc;
    } else {
      state.modifier_sets.push({
        doc: doc,
        value: doc,
        key: doc._id,
        id: doc._id,
      });
      /* need to sort the sets again */
      state.modifier_sets = state.modifier_sets.sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
    }
  },

  deleteModifierSetMutation(state, modifierset) {
    state.modifier_sets = state.modifier_sets.filter((modifier_set) => {
      return modifier_set.id !== modifierset.id;
    });
  },

  deleteCategoryMutation(state, data) {
    // console.log(data);
    state.categories = state.categories.filter((category) => {
      return category.id !== data.id;
    });
  },

  deleteDiscountMutation(state, data) {
    state.discounts = state.discounts.filter((discount) => {
      return discount.id !== data.id;
    });
  },

  initialiseCartFromLocalStorageMutation(state, value) {
    state.added = value.products;
    state.payments = value.payments;
  },

  initialiseCartFromHistoryMutation(state, value) {
    //console.log("cart from history", value);
    state.cartOrder = value;
    state.added = value.products;
    state.payments = value.payments;
    state.orderName = value.name;
    state.orderNotes = value.notes;
    state.orderDelivery = value.delivery;
    //console.log('state name: ', value);
  },

  addPaymentMutation(state, obj) {
    state.payments.push(obj);
  },

  clearCartMutation(state) {
    state.orderName = null;
    state.orderNotes = null;
    state.orderDelivery = null;
    state.payments = [];
    state.cartOrder = {};
    state.added = [];
    //console.log('cleared');
  },

  clearPaymentsMutation(state) {
    state.payments = [];
  },

  deletePaymentMutation(state, paymentIndex) {
    state.payments.splice(paymentIndex, 1);
  },

  setStripePaymentIntentMutation(state, intent) {
    state.stripePaymentIntent = intent;
  },

  setWebStoreMutation(state, status) {
    state.webStore = status;
  },

  setFuturePickupMutation(state, status) {
    state.futurePickup = status;
  },

  setStripeTotalMutation(state, total) {
    state.stripeTotal = total;
  },

  setStationMutation(state, station) {
    //console.log(station);
    state.station = station;
  },

  setSiteMutation(state, site) {
    state.site = site;
  },

  setCompanyMutation(state, company) {
    state.company = company;
  },

  setCurrencyMutation(state, currency) {
    state.currency = currency;
  },
};

const cartModule = {
  state,
  strict: true,
  getters,
  actions,
  mutations,
};

export default cartModule;
