<template>
  <router-view></router-view>
  <notifications position="bottom right" />
</template>

<script>
export default {
  name: "app",
  async beforeCreate() {
    const token = await window.localStorage.getItem("token");
    this.$store.dispatch("setToken", token);
  },
};
</script>

<style lang="scss">
.vue-notification-group {
  z-index: 111111 !important;

  .vue-notification {
    font-size: 24px;
    color: var(--banner-colour);
  }
}

:root {
  --primary-colour: #fff;
  --secondary-colour: #d5d8e1;
  --action-colour: #3bafda;
  --text-colour: #2e2f35;
  --secondary-text-colour: #fff;
  --warning-colour: #f44336;
  --banner-colour: #2e2f35;
  --border-colour: #ecf2f8;
  --hover-colour: #ededed;
  --opacity-background: rgba(0, 0, 0, 0.3);
  --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
}

:root.dark-theme {
  --primary-colour: #2e2f35;
  --secondary-colour: #444548;
  --action-colour: #3bafda;
  --text-colour: #d8d5d5;
  --secondary-text-colour: #2e2f35;
  --warning-colour: #f44336;
  --banner-colour: #2e2f35;
  --border-colour: #2e2f35;
  --hover-colour: #2e2f35;
  --opacity-background: rgba(0, 0, 0, 0.3);
  --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-colour: #2e2f35;
    --secondary-colour: #444548;
    --action-colour: #3bafda;
    --text-colour: #d8d5d5;
    --secondary-text-colour: #2e2f35;
    --warning-colour: #f44336;
    --banner-colour: #2e2f35;
    --border-colour: #2e2f35;
    --hover-colour: #2e2f35;
    --opacity-background: rgba(0, 0, 0, 0.3);
    --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  }

  :root.light-theme {
    --primary-colour: #fff;
    --secondary-colour: #d5d8e1;
    --action-colour: #3bafda;
    --text-colour: #2e2f35;
    --secondary-text-colour: #fff;
    --warning-colour: #f44336;
    --banner-colour: #2e2f35;
    --border-colour: #ecf2f8;
    --hover-colour: #ededed;
    --opacity-background: rgba(0, 0, 0, 0.3);
    --box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  }
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Lato";
  color: var(--text-colour);
  background-color: var(--primary-colour);
}

form {
  font-size: 1em;
  label {
    display: block;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  input[type="text"],
  input[type="file"],
  input[type="number"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  textarea {
    padding: 0.7rem;
    border-radius: 0.3rem;
    border: 1px solid #ccc;
    margin-bottom: 0.5rem;
    width: calc(100% - 2rem);
  }
}

button {
  padding: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  background-color: var(--action-colour);
  font-size: 1rem;
  color: var(--secondary-text-colour);
  font-family: "Lato";
  margin: 0 1rem;
  display: inline-block !important;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

button.primary {
  background-color: var(--action-colour) !important;
}

button.persist {
  background-color: #65da3b !important;
}

button.warning {
  background-color: #ff9700 !important;
}

button.danger {
  background-color: #ff003b !important;
}

button.action {
  padding: 0.7rem;
  border-radius: 0.3rem;
  border: 1px solid #ccc;
  background-color: #ddd;
  margin: 0.5rem;
  width: 200px;
  display: inline-block !important;
}

.ql-container {
  background-color: var(--primary-colour);
}
.ql-toolbar {
  background-color: var(--secondary-text-colour);
}
</style>
