<template>
  <div class="container">
    <slot />
    <footer>&copy;{{ year }} Beamy Ltd | <a href="/privacy">Privacy Policy</a></footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
div.container {
  background-color: var(--primary-colour);
  min-width: 100vw;
  padding: 2rem;
  box-sizing: border-box;
  min-height: 100vh;
}

footer {
  // position: absolute;
  bottom: 1em;
  font-size: 0.8em;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  padding-top: 20px;
  text-align: center;
  color: var(--text-colour);
  a {
    color: var(--text-colour);
  }
}
</style>
