import { Socket } from "phoenix";
import store from "../main";

class BagelSocket {
  async setUpChannel(_this) {
    const token = localStorage.getItem("token");
    const site = localStorage.getItem("site");
    const company = localStorage.getItem("company");
    const station = localStorage.getItem("station");
    const kdsBackendUrl = "wss://" + process.env.VUE_APP_API_HOST + "/socket";
    // const kdsBackendUrl = "ws://" + process.env.VUE_APP_API_HOST + "/socket";


    this.socket = _this.$.appContext.config.globalProperties.socket;
    this.channel = _this.$.appContext.config.globalProperties.channel;

    if (!store.getters.socketConnected) {
      this.socket = new Socket(kdsBackendUrl, {
        params: { guardian_token: token, station: station },
      });

      _this.$.appContext.config.globalProperties.socket = this.socket;

      console.log("Attempt to connect to socket with authentication");
      if (token && site) {
        //console.log('Ready to connect socket....')

        this.socket.connect();

        this.socket.onError((error) => {
          console.log("Socket error: ", error);
        });
        if (this.channel) this.channel.leave();
        if (store.getters.webStore) {
          this.channel = this.socket.channel("web:" + company + ":" + site, {});
        } else {
          this.channel = this.socket.channel("pos:" + company + ":" + site, {});
        }
        _this.$.appContext.config.globalProperties.channel = this.channel;

        this.channel
          .join()
          .receive("ok", (resp) => {
            store.dispatch("setSocketConnected", true);
            console.log("Joined successfully", resp);
          })
          .receive("error", (resp) => {
            store.dispatch("setSocketConnected", false);
            console.log("Unable to join", resp);
            //app.router.push('/station');
            location.href = "/login";
          })
          .receive("timeout", (resp) => {
            store.dispatch("setSocketConnected", false);
            console.log("Timeout", resp);
          });
      }
    }
  }
}

const bagelSocket = new BagelSocket();
export { bagelSocket };
